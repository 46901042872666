/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
  padding: 13px 30px;
  background-color: $white;

  .page-title {
    margin-bottom: 0px;
  }

  .breadcrumb {
    padding: 0px;
    margin: 0px;
    background: transparent;
    font-size: 12px;

    .breadcrumb-item + .breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: $gray-400;
      font-size: 11px;
    }
  }
}
