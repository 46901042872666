/*******************
 General Topbar
*******************/

.topbar {
  position: relative;
  z-index: 50;
  transition: $transitions;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
  background: $topbar;
  height: $topbar-height;

  .navbar-collapse {
    padding: 0px 10px 0 0;
  }

  .top-navbar {
    min-height: $topbar-height;
    padding: 0px;

    .dropdown-toggle::after {
      display: none;
    }

    .navbar-header {
      line-height: $topbar-navlink-height;
    }

    .navbar-brand {
      display: flex;
      align-items: center;
      margin: 0px;
      padding: $topbar-navbrand-padding;

      .dark-logo {
        display: none;
      }

      .logo-icon {
        margin-right: 10px;
      }
    }

    .navbar-nav > .nav-item > .nav-link {
      padding: $topbar-navlink-padding;
      font-size: $topbar-navlink-font-size;
      line-height: $topbar-navlink-height;
      height: $topbar-navlink-height;
    }

    .navbar-nav > .nav-item {
      transition: 0.2s ease-in;

      &:hover {
        background: $transparent-dark-bg;
      }
    }
  }

  .nav-toggler,
  .topbartoggler {
    color: $theme-light;
    padding: 0 15px;
  }

  .sidebartoggler.side-start {
    justify-content: center;
    margin-left: 0;
    color: $white;
    font-size: 20px;
  }
}

.app-search {
  margin-top: 15px;
  position: relative;

  .form-control {
    border-radius: 60px;
    border-color: transparent;
    transition: 0.2s ease-in;
    width: 180px;
    height: 30px;

    &:focus {
      width: 300px;
    }
  }

  a {
    position: absolute;
    top: 5px;
    right: 10px;
    color: $gray-600;
  }
}

/*******************
Megamenu
******************/
.topbar {
  .mega-dropdown {
    position: static;

    .dropdown-menu {
      padding: 30px;
      @extend %square;
      width: 100%;
      min-height: 480px;
      overflow: auto;
      transform: translate(0px, 58px) !important;
    }
  }
}

/*******************
Designed dropdown
******************/
.topbar {
  .dropdown-menu {
    padding-top: 0px;
    border: 0px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  }
}

/*******************
Topbar Responsive
******************/

@media (max-width: 767px) {
  .topbar .top-navbar {
    .navbar-collapse.show,
    .navbar-collapse.collapsing {
      display: block;
      width: 100%;
      border-top: 1px solid $border-color;
    }

    .navbar-nav {
      flex-direction: row;

      .dropdown {
        position: static;
      }
    }

    .navbar-nav > .nav-item > .nav-link {
      padding: 0 10px;
    }

    .navbar-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }

    .dropdown-menu {
      position: absolute;
      width: 100%;
      left: 0 !important;
    }
  }
}

@media (max-width: 767px) {
  .topbar .top-navbar {
    .navbar-header {
      position: fixed;
      z-index: 10;
    }
    .navbar-collapse.show {
      position: relative;
      top: 60px;
    }
  }
}
