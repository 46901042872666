.feed-widget {

    .feed-body {
        .feed-item {
            padding: 12px 0;
            display: flex;
            align-items: center;
            &:hover {
                background: $gray-100;
            }
            >.feed-icon {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                border-radius: 100%;
                color: $white;
                i {
                    line-height: 40px;
                }
            }
        }
    }
}

.chatonline {
    padding: 0;
    list-style: none;
    li {
        padding: 13px 0;
        position: relative;
        cursor: pointer;
        .call-chat {
            position: absolute;
            right: 0;
            display: none;
            .btn-circle {
                .btn-lg {
                    width: 50px;
                    height: 50px;
                    padding: 10px 16px;
                    border-radius: 25px;
                    font-size: 18px;
                    line-height: 1.33;
                    .fa {
                        color: $white;
                    }
                }
            }
        }
        &:hover {
            .call-chat {
                display: block;
            }
        }
        a {
            width: 100%;
        }
        img {
            width: 40px;
            margin-right: 10px;
            border-radius: 50%;
        }
        small {
                font-size: 10px;
            }
    }
}