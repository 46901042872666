// Theme colors
#main-wrapper {

    &[data-layout="vertical"],
    &[data-layout="horizontal"] {

        /*This is for the logo bg*/
        .topbar .top-navbar .navbar-header {
            &[data-logobg="skin1"] {
                background: $skin1;
            }

            &[data-logobg="skin2"] {
                background: $skin2;
            }

            &[data-logobg="skin3"] {
                background: $skin3;
            }

            &[data-logobg="skin4"] {
                background: $skin4;
            }

            &[data-logobg="skin5"] {
                background: $skin5;
            }

            &[data-logobg="skin6"] {
                background: $skin6;

                .navbar-brand {
                    .dark-logo {
                        display: inline;
                    }

                    .light-logo {
                        display: none;
                    }
                }

                .nav-toggler,
                .topbartoggler {
                    color: $body-color;
                }

                .sidebartoggler.side-start {
                    color: $dark;
                    opacity: .9;
                }
            }
        }

        .topbar .navbar-collapse,
        .topbar {
            &[data-navbarbg="skin1"] {
                background: $skin1;
            }

            &[data-navbarbg="skin2"] {
                background: $skin2;
            }

            &[data-navbarbg="skin3"] {
                background: $skin3;
            }

            &[data-navbarbg="skin4"] {
                background: $skin4;
            }

            &[data-navbarbg="skin5"] {
                background: $skin5;
            }

            &[data-navbarbg="skin6"] {
                background: $skin6;

                .navbar-brand {
                    .dark-logo {
                        display: inline;
                    }

                    .light-logo {
                        display: none;
                    }
                }

                .search-box {
                    .form-control {
                        border-color: rgba(120, 130, 140, 0.13);
                    }
                }
            }
        }

        /*This is for the sidebar bg*/
        .left-sidebar {

            &[data-sidebarbg='skin1'],
            &[data-sidebarbg='skin1'] ul {
                background: $skin1;
            }

            &[data-sidebarbg='skin2'],
            &[data-sidebarbg='skin2'] ul {
                background: $skin2;

                .sidebar-nav {
                    ul {
                        .sidebar-item {
                            &.selected>.sidebar-link {
                                /*background: rgba(0, 0, 0, 0.04);*/
                                opacity: 1;
                                background-color: $skin5;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            &[data-sidebarbg='skin3'],
            &[data-sidebarbg='skin3'] ul {
                background: $skin3;

                .sidebar-nav {
                    ul {
                        .sidebar-item {
                            &.selected>.sidebar-link {
                                /*background: rgba(0, 0, 0, 0.04);*/
                                opacity: 1;
                                background-color: $skin5;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            &[data-sidebarbg='skin4'],
            &[data-sidebarbg='skin4'] ul {
                background: $skin4;

                .sidebar-nav {
                    ul {
                        .sidebar-item {
                            &.selected>.sidebar-link {
                                /*background: rgba(0, 0, 0, 0.04);*/
                                opacity: 1;
                                background-color: $skin5;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            &[data-sidebarbg='skin5'],
            &[data-sidebarbg='skin5'] ul {
                background: $skin5;

                .sidebar-item {
                    .sidebar-link {
                        opacity: 0.4;

                        &.active,
                        &:hover {
                            opacity: 1;
                        }
                    }

                    &.selected>.sidebar-link {
                        opacity: 1;
                    }

                    .first-level {
                        .sidebar-item.active>.sidebar-link {
                            opacity: 1;
                        }
                    }

                    .sidebar-link.profile-dd {
                        opacity: 1;
                    }
                }
            }

            &[data-sidebarbg='skin6'] {
                background: $skin6;

                .sidebar-nav {
                    ul {
                        background: $skin6;

                        .sidebar-item {
                            .sidebar-link {
                                color: $sidebar-text-dark;
                                opacity: 0.9;

                                i {
                                    color: $sidebar-icons-dark;
                                }

                                &.active,
                                &:hover {
                                    opacity: 1;
                                }
                            }

                            .profile-dd span {
                                color: $sidebar-text-dark;
                            }

                            &.selected>.sidebar-link {
                                opacity: 1;
                                color: $white;

                                i {
                                    color: $white;
                                }
                            }
                        }
                    }

                    .has-arrow {
                        &::after {
                            border-color: $sidebar-text-dark;
                        }
                    }

                    .sidebar-item.selected>.sidebar-link.has-arrow {
                        &::after {
                            border-color: $white;
                        }
                    }

                    .devider {
                        border-top: 1px solid $sidebar-text-dark;
                        opacity: .1;
                    }

                    .user-content,
                    .user-name,
                    .user-email {
                        opacity: 0.9;
                        color: $theme-dark;
                    }
                }
            }
        }
    }
}
