// App

/*============================================================== 
 For all pages 
 ============================================================== */

#main-wrapper {
  width: 100%;
  overflow: hidden;
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: 0.2s ease-in;
}

.page-wrapper > .container-fluid {
  padding: 25px;
  min-height: calc(100vh - 180px);
}

/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
}

/*******************
 Vertical layout
*******************/

#main-wrapper[data-layout="vertical"] {
  /*Vertical*/
  .topbar .top-navbar .navbar-header {
    //width: $sidebar-width-full;
    transition: 0.2s ease-in;
  }

  /*sidebar type*/
  &[data-sidebartype="full"] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  }

  /*Header position*/
  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .page-wrapper {
      padding-top: $topbar-height;
    }
  }
}

/*******************
 Vertical responsive layout
*******************/

@include media-breakpoint-up(md) {
  #main-wrapper[data-layout="vertical"] {
    /*Vertical*/
    &[data-sidebartype="full"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }

      .topbar {
        .top-navbar {
          .sidebartoggler.side-start {
            justify-content: flex-start;
            margin-left: 1.5rem;
            color: $white;
          }
        }
      }
    }

    /*Sidebar position*/
    &[data-sidebar-position="fixed"][data-sidebartype="full"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-full;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    /*Vertical with mini-sidebar*/
    &[data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }

        &.expand-logo {
          width: $sidebar-width-full;

          .logo-text {
            display: block;
          }
        }
      }
    }

    /*Fixed sidebar with minisidebar*/
    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebartype="mini-sidebar"] {
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      .sidebar-nav {
        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        .nav-small-cap {
          margin-left: 5px;
        }
      }

      .navbar-header {
        .navigation-text {
          display: none;
        }
      }

      .navbar-header.expand-logo {
        .navigation-text {
          display: block;
        }

        .sidebartoggler.side-start {
          justify-content: flex-start;
          margin-left: 1.5rem;
        }
      }

      .left-sidebar {
        width: $sidebar-width-mini;

        &:hover {
          width: $sidebar-width-full;

          .sidebar-nav {
            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
            }
          }

          .first-level {
            .sidebar-item .sidebar-link i {
              visibility: hidden;
            }
          }
        }

        .first-level {
          .sidebar-item .sidebar-link i {
            visibility: visible;
          }
        }
      }
    }
  }
}

@media (max-width: 766px) {
  #main-wrapper {
    &[data-sidebartype="mini-sidebar"] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }

    &[data-layout="vertical"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }

    /*Header position*/
    &[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .sidebar-nav ul .sidebar-item {
    width: $sidebar-width-mini !important;
  }
  .btn-danger {
    display: none;
  }
}
