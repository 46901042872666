// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
a {
  text-decoration: none;
}

a:hover {
  color: $fresian-fushia;
  text-decoration: underline;
}

.scrollBox {
  height: 200px;
  overflow-y: auto;
  background: $white;
  border: none;
  border-radius: 10px;
  padding: 20px;
}

i.fa.fa-question-circle:hover {
  color: $thoroughbred-teal;
}

.special-radio label.btn {
  height: 70px;
  border-radius: 10px;
  font-weight: bold;
  vertical-align: middle;
  line-height: 54px !important;
  min-width: 100px;
}

.special-radio label.btn.active {
  background: $fresian-fushia !important;
  color: $white !important;
}

.rider-radio {
  pointer-events: none;
}

.rider-radio label.active {
  color: $white;
  background-color: $fresian-fushia;
  opacity: 1;
}

#progressBar {
  width: 100%;
  padding: 30px 0 40px 0;
  height: 100px;
  border-bottom: 1px solid #eeeeee;
  -webkit-box-shadow: 0 3px 15px -6px #333;
  -moz-box-shadow: 0 3px 15px -6px #333;
  box-shadow: 0 3px 15px -6px #333;
}

.progress-bar {
  background-color: #1ec4b0;
  padding: 5px;
  border: 3px solid #20233f;
  border-radius: 10px;
}

.progress {
  height: auto;
  background-color: #20233f;
  border-radius: 10px;
}

button:disabled,
button[disabled] {
  border: 1px solid #878787;
  background-color: #e7bcff;
  color: #878787;
}

i.fa:not(.fa-2x) {
  cursor: pointer;
}

.btn {
  border-radius: 25px;
}

.list-group {
  max-height: 50vh;
  overflow-y: auto;
}

.ngb-toasts {
  left: 45%;
}

.invalid-feedback {
  display: block;
}

.btn-image {
  background: $white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% -10px;
  width: 100%;
  height: 150px;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
}

.btn-image.active {
  border: 1px solid $fresian-fushia;
}
