// ======================================================
// Custom layout variables
// ======================================================
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap"); 

// ======================================================
// Theme Global setting colors
// ======================================================

$themecolor: #2cabe3;
$themecolor-dark: #028ee1;
$themecolor-alt: #26c6da;
$theme-light: #fff;
$theme-dark: #212529;

$skin1: #2cabe3;
$skin2: #707cd2;
$skin3: #ff7676;
$skin4: #a0aec4;
$skin5: #2f323e;
$skin6: #fff;

// ======================================================
// Topbar settings
// ======================================================

$topbar: $theme-light;
$topbar-height: 60px;
$topbar-navlink-padding: 0px 14px;
$topbar-navlink-font-size: 14px;
$topbar-navlink-height: 60px;
$topbar-navbrand-padding: 0 10px 0 10px;

// ======================================================
// Sidebar settings
// ======================================================

$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$sidebar-text-dark: #54667a;
$sidebar-icons-dark: #54667a;
$sidebar-width-full: 240px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 65px;

// ======================================================
// Layout settings
// ======================================================
$page-wrapper-max-width: 1200px;

// ======================================================
// Common settings
// ======================================================
$shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
$transitions: 0.2s ease-in;
$transparent-dark-bg: rgba(0, 0, 0, 0.03);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;

// ======================================================
// Mixin settings
// ======================================================
%square {
  border-radius: 0px;
}

%rotate45 {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

// ======================================================
// Bootstrap override colors
// ======================================================
$white: #fff !default;
$gray-100: #f7fafc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #8898aa !default;
$gray-600: #8898aa !default;
$gray-700: #525f7f !default;
$gray-800: #313131 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2962ff !default;
$indigo: #6610f2 !default;
$purple: #7460ee !default;
$pink: #e83e8c !default;
$red: #f33155 !default;
$orange: #fb8c00 !default;
$yellow: #ffbc34 !default;
$green: #7ace4c !default;
$teal: #20c997 !default;
$cyan: #4fc3f7 !default;
$primary: #9133f5 !default;
$text-muted: $gray-500 !default;

$light-primary: #c6cdfd !default;
$light-info: #cfecfe !default;
$light-warning: #ffedd4 !default;
$light-danger: #ffcaca !default;
$light-success: #b2ffd9 !default;

$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: #2cabe3 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$muted: $gray-500 !default;
$cyan: $cyan !default;
$orange: $orange !default;
$theme-colors: () !default;

$pony-power-pink: #fe007d;
$pony-pale-pink: #ff96ca;
$thoroughbred-teal: #1ec4b0;
$teal-pale: #a5e7df;
$fresian-fushia: #9133f5;

$theme-colors: map-merge(
  (
    "primary": $fresian-fushia,
    "secondary": $gray-600,
    "success": $green,
    "info": $info,
    "warning": #ffc36d,
    "danger": $red,
    "light": $gray-100,
    "dark": $gray-800,
    "pony-power-pink": $pony-power-pink,
    "pony-pale-pink": $pony-pale-pink,
    "thoroughbred-teal": $thoroughbred-teal,
    "teal-pale": $teal-pale,
    "hi-viz-hacker": #e9fc60,
    "hi-viz-pale": #f4fdaf,
    "soft-lilac": #e3d2ff,
    "lilac-pale": #f1e9ff,
    "friesian-fuchsia": $fresian-fushia,
    "primary-navy": #20233f,
    "white-shoulders": #f1efec
  ),
  $theme-colors
);

// ======================================================
// Dark Theme Colors Settings
// ======================================================
$theme-dark-bg-layout: #323743;
$theme-dark-text-color: #b2b9bf !default;
$theme-dark-border-color: rgba(255, 255, 255, 0.2) !default;
$theme-dark-focus-border-color: rgba(255, 255, 255, 0.4) !default;
$theme-dark-card-bg: #272b34;
$theme-dark-bg-light: #3c424e;
$main-body-bg-dark: $gray-900 !default;
$background-alt: #2b2b2b !default;

// ======================================================
// Enable optional
// ======================================================
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: false !default;

// ======================================================
// Body
// ======================================================
$main-body-bg: #fff !default;
$body-bg: #edf1f5;
$body-color: #313131 !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.08) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
// Style anchor elements.
$link-hover-decoration: none !default;

// ======================================================
// Typography
// ======================================================
$font-size-base: 0.875rem !default;
$font-family-sans-serif: "Poppins", sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-base: $font-weight-light !default;

// $h1-font-size: 3rem !default;
// $h2-font-size: 1.8rem !default;
// $h3-font-size: 2rem !default;
// $h4-font-size: 1.25rem !default;
// $h5-font-size: 0.9rem !default;
// $h6-font-size: 0.75rem !default;

$display5-size: 3rem !default;
$display6-size: 2.5rem !default;
$display7-size: 2rem !default;
$display5-weight: bold !default;
$display6-weight: bold !default;
$display7-weight: bold !default;

$headings-margin-bottom: (1rem / 2) !default;
$label-margin-bottom: (0.8rem / 2) !default;
$paragraph-margin-bottom: (1rem / 2) !default;
$headings-font-weight: 400 !default;
$headings-color: inherit !default;

// ======================================================
// Breadcrumb
// ======================================================
$breadcrumb-bg: $body-bg;
$breadcrumb-margin-bottom: 1.5rem;

// ======================================================
// Cards
// ======================================================
$card-border-width: 0px !default;
$card-border-color: transparent !default;
$card-border-radius: 0px !default;
$border-radius: .5rem !default;
$border-radius-lg: .8rem !default;
$border-radius-sm: .3rem !default;

// ======================================================
// Dropdowns
// ======================================================
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.65rem !default;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// ======================================================
// Buttons
// ======================================================
$btn-secondary-border: $gray-300;
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$active-background: $fresian-fushia !default;

// ======================================================
// Progressbar
// ======================================================
$progress-bg: $gray-100;

// ======================================================
// Tables
// ======================================================
$table-cell-padding: 0.9375rem 0.4rem !default;

// ======================================================
// Components
// ======================================================
$component-active-color: $white !default;
$component-active-bg: $themecolor !default;
$badge-pill-padding-x: 0.2em !default;
$badge-pill-padding-y: 1em !default;
$badge-font-size: 12px !default;

$custom-control-indicator-active-bg: rgba(0, 0, 0, 35%) !default;
$input-btn-focus-box-shadow: transparent !default;
$custom-select-focus-box-shadow: transparent !default;

// ======================================================
// Forms
// ======================================================
$input-group-addon-bg: $gray-100;
$input-border-color: $fresian-fushia;
$input-group-addon-border-color: $gray-200;
$input-btn-focus-color: rgba(0, 0, 0, 0.25) !default;
$input-focus-border-color: rgba(0, 0, 0, 0.25) !default;
$input-box-shadow: none !default;
$input-btn-focus-box-shadow: transparent !default;
$custom-select-focus-box-shadow: transparent !default;
$border-width: 1px !default;
$border-color: rgba(120, 130, 140, 0.13) !default;
$input-font-size: 0.9rem !default;
$input-color: $black !default;
$input-border-radius: 5px !default;
$custom-checkbox-indicator-border-radius: .25rem !default;

// ======================================================
// Progressbars
// ======================================================
$progress-height: 5px !default;

// ======================================================
// Tabs
// ======================================================
$nav-tabs-link-active-bg: $white !default;

// ======================================================
// Grid breakpoints
// ======================================================
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1600px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;

// ======================================================
// Navbar
// ======================================================
$navbar-dark-color: rgba($white, 0.8) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;
